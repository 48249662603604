import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "badge"
    }}>{`Badge`}</h1>
    <p>{`Badges commonly used for notifications or small counts`}</p>
    <h2 {...{
      "id": "exemple"
    }}>{`Exemple`}</h2>
    <span className='badge mb-2'></span><br />
    <span className='badge mb-2'>10</span><br />
    <span className='badge background-yellow black mb-2'>100</span><br />
    <span className='badge background-yellow black mb-2 rounded'>100</span><br />
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<span class='badge mb-2'></span>
<span class='badge mb-2'>10</span>
<span class='badge background-yellow black mb-2'>100</span>
<span class='badge background-yellow black mb-2 rounded'>100</span>
`}</code></pre>
    <h2 {...{
      "id": "over-element"
    }}>{`Over Element`}</h2>
    <i className="far fa-comment-alt-lines mb-3 position-relative" style={{
      "fontSize": "20px"
    }}>
  <span className='badge over-element'></span>
    </i><br />
    <i className="far fa-comment-alt-lines mb-3 position-relative" style={{
      "fontSize": "20px"
    }}>
  <span className='badge over-element top-left'>10</span>
    </i><br />
    <i className="far fa-comment-alt-lines mb-3 position-relative" style={{
      "fontSize": "20px"
    }}>
  <span className='badge background-yellow black over-element bottom-right'>100</span>
    </i><br />
    <i className="far fa-comment-alt-lines mb-3 position-relative" style={{
      "fontSize": "20px"
    }}>
  <span className='badge background-yellow black rounded over-element bottom-left'>100</span>
    </i><br />
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<i class="far fa-comment-alt-lines position-relative">
  <span class='badge over-element'></span>
</i>
<i class="far fa-comment-alt-lines position-relative">
  <span class='badge over-element top-left'>10</span>
</i>
<i class="far fa-comment-alt-lines position-relative">
  <span class='badge background-yellow black over-element bottom-right'>100</span>
</i>
<i class="far fa-comment-alt-lines position-relative">
  <span class='badge background-yellow black rounded over-element bottom-left'>100</span>
</i>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      